.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: #404f65;
    color: #c1edff;
    font-size: 30px;
    border-radius: 10px;
    overflow: auto;
    white-space: inherit;
    word-wrap: break-word;

}

.smallcard {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: #404f65;
    color: #c1edff;
    font-size: 22px;
    border-radius: 10px;
    overflow: auto;
    white-space: inherit;
    word-wrap: break-word;

}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor:pointer;
  }

  .smallcard:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor:pointer;
  }


.cardcontent {
    padding: 25px 30px;
}

.smallcardcontent {
    padding: 20px 25px;
}

.cardcontentsecondary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #98c8db;
    font-size: 20px;
}

.cardcopied {
    color: #FFD9A8;
}

.cardnotcopied {
    color: #c1edff;
}


.copyiconhidden {
    display: none;
}