.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.applogo {
  height: auto;
  width: 100px;
  align-items: center;
  justify-content: center;
  margin-left: 25%;
  margin-right: 25%;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* You can do this by putting the text in a span and using flex to align the span to the bottom of the label which will need to have display: flex. The simplest approach is margin-top:auto on the span in the label that has display: flex, or using justify-content: flex-end to force the spans to the bottom of the labels.

EDIT: Spans removed following feedback. THX */

.row {
  margin: 0 -15px
}

.col-lg-3 {
  width: 25%;
  float: left;
  padding: 0 15px;
  margin-bottom: 15px
}

.versionDiv {

  bottom: 0;
  height: 3em;
  width: 100%;
  text-align: center;
  color: #A5D9EE;
  background-color: #282b39;
}

::-webkit-scrollbar {
  width: 0.4em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #202124;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3D4D70;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}